export enum ELEMENT_TYPES {
  "IFRAME" = "IFRAME",
  "LINK" = "LINK",
  "TEXT" = "TEXT",
  "SCREENSHARE" = "SCREENSHARE",
  "IMAGE" = "IMAGE",
}

export enum ROOM_THEME {
  "dark" = "dark",
  "darkest" = "darkest",
  "light" = "light",
}

export enum ROOM_TYPE {
  "WEBINAR" = "WEBINAR",
  "CALL" = "CALL",
}

export const RTC_WD = "wss://cake.livekit.cloud";

export enum TARIFFS {
  FREE = "FREE",
  TRIAL = "TRIAL",
  STARTER = "STARTER",
  PROFESSIONAL = "PROFESSIONAL",
  NONE = "NONE",
}
export enum PERIOD {
  MONTH,
  YEAR,
}
