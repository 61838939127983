import { Paper, Title, Container, Button } from "@mantine/core";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import styles from "./resetPassword.module.scss";
import { useTranslation } from "react-i18next";
import LogoLight from "../../../components/logoLight/LogoLight";
import { useParams } from "react-router-dom";

interface FormProps {
  password: string;
}

const ResetPassword = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const { hash } = useParams<{ hash: string }>();

  const [success, showSuccess] = useState(false);
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      password: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    const res = await authStore.resetPassword(data.password, hash);

    if (res.ok) {
      showSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <LogoLight />
        </div>
        <Title align="center" sx={(theme) => ({ fontWeight: 700 })}>
          {t("reset.header", { defaultValue: "Reset your password" })}
        </Title>

        {success ? (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            {t("reset.success", {
              defaultValue: "Success! Now you can login with new password!",
            })}
          </Paper>
        ) : (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <FormInput
              type="password"
              rules={{
                required: true,
              }}
              error={errors.password}
              control={control}
              label={t("reset.new.password", {
                defaultValue: "Enter new password",
              })}
              name={"password"}
            />
            <Button
              fullWidth
              mt="xl"
              loading={isSubmitting}
              onClick={handleSubmit(login)}
            >
              {t("reset.change", { defaultValue: "Change password" })}
            </Button>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default ResetPassword;
