import React, { useEffect } from "react";

import { observer } from "mobx-react";
import { useStore } from "../../hooks/use-store";
import FullscreenLoader from "../space/fullscreenLoader/Loader";
import ReconnectIndicator from "../space/reconnectIndicator/ReconnectIndicator";
import { nanoid } from "nanoid";
import DyteRoom from "../../pages/experiment/DyteRoom";

import styles from "./space.module.scss";
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    height: "100%",
  },
}));
const Meeting = (model: { spaceId: string; roomId: string }) => {
  const {
    uiStore: { authStore },
    dataStore: { spaceStore },
  } = useStore();

  const { classes } = useStyles();
  const identityId = authStore.currentUser
    ? authStore.currentUser.identityId
    : nanoid();
  const username = authStore.currentUser
    ? `${authStore.currentUser!.firstName} ${authStore.currentUser!.lastName}`
    : nanoid();
  const avatar = authStore.currentUser
    ? authStore.currentUser!.avatar
    : nanoid();

  useEffect(() => {
    spaceStore.connect(
      identityId,
      model.spaceId,
      username,
      avatar,
      model.roomId
    );
    // elementsStore.connect(model.roomId, identityId);
    // chatStore.connect(model.roomId, identityId);

    return () => {
      (async () => await spaceStore.disconnect())();
      // (async () => await elementsStore.disconnect())();
      // (async () => await chatStore.disconnect())();
    };
  }, [
    model.spaceId,
    spaceStore,
    authStore,
    model.roomId,
    username,
    avatar,
    identityId,
  ]);

  return (
    <div className={classes.root}>
      <FullscreenLoader />
      <ReconnectIndicator />
      <div className={styles.guestMeeting}>
        {spaceStore.ready ? (
          spaceStore.currentRoom?.receptionRoom ? null : (
            <DyteRoom
              token={spaceStore.streamToken}
              theme={spaceStore.currentRoom?.theme}
            />
          )
        ) : null}
      </div>
    </div>
  );
};

export default observer(Meeting);
