/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  rem,
  ActionIcon,
} from "@mantine/core";
import {
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight,
  IconPlus,
} from "@tabler/icons-react";

import { observer } from "mobx-react";
import { Avatar, Modal, Checkbox, Tooltip } from "antd";
// @ts-ignore
import Pluralize from "react-pluralize";
import { getInitials } from "../../helpers/helper";
// import styles from "./sidebar.module.scss";
import { useStore } from "../../hooks/use-store";
import AddRoom from "./addRoom/AddRoom";

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  roomItem: {
    background: "transparent",
    borderRadius: "6px",

    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),

    // marginBottom: '4px',
    // margin-left: 8px;
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",

    fontSize: "0.975rem",
    // color: '#495057',
    lineHeight: "1",
    fontWeight: 500,

    position: "relative",
  },

  roomNameContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  roomName: {
    fontStyle: "normal",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: "1.25rem",
    fontVariant: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
  },

  roomCounter: {
    whiteSpace: "nowrap",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    // fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "11px",
  },

  addIcon: {
    display: "flex",
    alignItems: "center",
    // opacity: 0.8,
    // width: '100%'
  },

  roomParticipantContainer: {
    display: "flex",
    marginTop: "12px",
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    // padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    // paddingLeft: rem(12),

    marginLeft: rem(30),
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },

  inline: {
    display: "flex",
    "> span": {
      marginLeft: "6px",
    },
  },
}));

const Sessions = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();
  const participants = Array.from(spaceStore.participantMap.values());
  const [addRoomDrawerOpen, setAddRoomDrawerOpen] = useState(false);

  const changeRoom = async (roomId: string) => {
    if (spaceStore.currentRoom?.receptionRoom) {
      spaceStore.changeRoom(roomId);
    } else {
      const showConfirm = window.localStorage.getItem("confirmOnRoomChange");
      if (!showConfirm) {
        Modal.confirm({
          onOk: async () => {
            spaceStore.changeRoom(roomId);
          },
          onCancel: () => {},
          okText: "Onward!",
          cancelText: "Stay here",
          title: "Hey, you are about to leave this room.",
          content: (
            <div>
              <p>
                People in this room will no longer see or hear you, until you
                return.
              </p>
              <Checkbox
                onChange={() => {
                  window.localStorage.setItem("confirmOnRoomChange", "true");
                }}
              >
                Don't ask again
              </Checkbox>
            </div>
          ),
        });
      } else {
        spaceStore.changeRoom(roomId);
      }
    }
  };

  const { classes, theme } = useStyles();
  const hasLinks = true;
  const [opened, setOpened] = useState(true);
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  // const items = (hasLinks ? links : []).map((link) => (
  //   <Text<'a'>
  //     component="a"
  //     className={classes.link}
  //     href={link.link}
  //     key={link.label}
  //     onClick={(event) => event.preventDefault()}
  //   >
  //     {link.label}
  //   </Text>
  // ));
  const items = (
    <div>
      {spaceStore.rooms
        .filter((itm) => !itm.hidden)
        .filter((itm) => !itm.receptionRoom)
        .map((itm) => {
          const roomParticipants = participants.filter(
            (p) => p.roomId === itm.roomId
          );

          return (
            <Text className={classes.link}>
              <div
                key={itm.roomId}
                className={classes.roomItem}
                onClick={() => {
                  if (itm.roomId !== spaceStore.currentRoom?.roomId) {
                    spaceStore.canJoinOtherRoom
                      ? changeRoom(itm.roomId)
                      : console.log("noo");
                  }
                }}
                style={
                  spaceStore.currentRoom?.roomId === itm.roomId
                    ? { background: "#f8f9fa" }
                    : {}
                }
              >
                <div className={classes.roomNameContainer}>
                  <div className={classes.roomName}>{itm.name}</div>
                  <div className={classes.roomCounter}>
                    <span>
                      <Pluralize
                        singular={"user"}
                        count={roomParticipants.length}
                        zero={"no users"}
                      />
                    </span>
                  </div>
                </div>
                {roomParticipants.length > 0 ? (
                  <div className={classes.roomParticipantContainer}>
                    <Avatar.Group
                      maxCount={8}
                      maxStyle={{
                        color: "white",
                        backgroundColor: "#100b18",
                        borderColor: "#100b18",
                      }}
                    >
                      {roomParticipants.map((rp) => (
                        <Fragment key={rp.identityId}>
                          <Tooltip title={rp.username} arrowPointAtCenter>
                            <Avatar
                              //shape="square"

                              style={{
                                border: "none",
                                backgroundColor: "#272b31",
                                cursor: "pointer",
                              }}
                              size={32}
                              src={rp.avatar}
                            >
                              {getInitials(rp.username)}
                            </Avatar>
                          </Tooltip>
                        </Fragment>
                      ))}
                    </Avatar.Group>
                  </div>
                ) : null}
              </div>
            </Text>
          );
        })}
    </div>
  );

  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      })}
    >
      <UnstyledButton
        onClick={() => setOpened((o: boolean) => !o)}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <IconCalendarStats size="1.1rem" />
            </ThemeIcon>
            <Box ml="md">Sessions</Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                transform: opened
                  ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                  : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      <AddRoom
        visible={addRoomDrawerOpen}
        onClose={() => setAddRoomDrawerOpen(false)}
      />
      {hasLinks ? (
        <Collapse in={opened}>
          {items}
          <Text className={classes.link}>
            <div
              className={classes.roomItem}
              onClick={() => {
                setAddRoomDrawerOpen(true);
              }}
            >
              <div className={classes.roomNameContainer}>
                <div className={classes.roomName}>
                  <div className={classes.inline}>
                    <ActionIcon
                      variant="default"
                      size={20}
                      className={classes.addIcon}
                      // onClick={() => {
                      //   setAddRoomDrawerOpen(true);
                      // }}
                    >
                      <IconPlus size="0.8rem" stroke={1.5} />
                    </ActionIcon>
                    <span>add session</span>
                  </div>
                </div>
              </div>
            </div>
          </Text>
        </Collapse>
      ) : null}
    </Box>
  );
};

export default observer(Sessions);
