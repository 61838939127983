import { useState } from "react";
import dayjs from "dayjs";
import { createStyles, Text, Paper, Group, rem } from "@mantine/core";
import { useStore } from "../../../hooks/use-store";

const useStyles = createStyles((theme) => ({
  root: {
    backgroundImage: `linear-gradient(-60deg, ${theme.colors.gray[9]} 0%, ${theme.colors.gray[7]} 100%)`,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: "flex",
    maxWidth: "660px",
    margin: "0 auto 12px auto",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  icon: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing.lg,
    color: theme.colors[theme.primaryColor][6],
  },

  stat: {
    minWidth: rem(98),
    paddingTop: theme.spacing.xl,
    minHeight: rem(140),
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.white,
    height: "100%",
  },

  label: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
    lineHeight: 1.2,
  },

  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },

  value: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    color: theme.colors.gray[6],
  },

  count: {
    color: theme.colors.gray[6],
  },

  day: {
    fontSize: rem(44),
    fontWeight: 700,
    color: theme.white,
    lineHeight: 1,
    textAlign: "center",
    marginBottom: 5,
    marginTop: 5,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  month: {
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    lineHeight: 1,
    textAlign: "center",
  },

  controls: {
    display: "flex",
    flexDirection: "column",
    marginRight: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "row",
      alignItems: "center",
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
  },

  date: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  control: {
    height: rem(28),
    width: "100%",
    color: theme.colors[theme.primaryColor][2],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.radius.md,
    transition: "background-color 50ms ease",

    [theme.fn.smallerThan("xs")]: {
      height: rem(34),
      width: rem(34),
    },

    "&:hover": {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.white,
    },
  },

  controlIcon: {
    [theme.fn.smallerThan("xs")]: {
      transform: "rotate(-90deg)",
    },
  },
}));

export function Calendar() {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const { classes } = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState(new Date());

  const stats = spaceStore.rooms
    .sort((itm1, itm2) => +itm1.welcomePageDate - +itm2.welcomePageDate)
    .filter((itm) => !itm.receptionRoom)
    .slice(0, 3)
    .map((room) => {
      const date = dayjs(new Date(room.welcomePageDate));
      return (
        <Paper
          className={classes.stat}
          radius="md"
          shadow="md"
          p="xs"
          key={room.roomId}
        >
          <div className={classes.card}>
            <Text className={classes.label}>{room.name}</Text>
            <Text fz="xs" className={classes.count}>
              <span className={classes.value}>
                {
                  // '10 p.m CET / 12.12.2023'
                  room.welcomePageEnabled
                    ? `At ${date.format("D MMM")} ${date.format("h:ma Z")}`
                    : "No time is set"
                }
              </span>
            </Text>
          </div>
        </Paper>
      );
    });

  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <div className={classes.date}>
          <Text className={classes.month}>Today is</Text>
          <Text className={classes.day}>{dayjs(date).format("DD")}</Text>
          <Text className={classes.month}>{dayjs(date).format("MMMM")}</Text>
        </div>
      </div>
      <Group sx={{ flex: 1 }}>{stats}</Group>
    </div>
  );
}
