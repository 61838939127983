import React, { useState } from "react";
import { useStore } from "../../../hooks/use-store";
import { Drawer, Form, Col, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useRequest } from "ahooks";
import dayjs from "dayjs";

interface Props {
  onClose: () => void;
  visible: boolean;
  roomId: string;
}

const RoomRecordings = (props: Props) => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const [recordings, setRecordings] = useState([]);

  const { loading } = useRequest(
    async () => spaceStore.getRecordingsList({ roomId: props.roomId }),
    {
      loadingDelay: 300,
      // manual: true,
      onSuccess: (model) => {
        console.log(model);
        setRecordings(
          (model as any).map((itm: any) => ({
            date: itm.createdAt,
            name: itm.name,
            link: itm.fileName,
            duration: `${dayjs(itm.stoppedTime).diff(
              dayjs(itm.startedTime),
              "hours"
            )}h, ${
              dayjs(itm.stoppedTime).diff(dayjs(itm.startedTime), "minutes") %
              60
            }min, ${
              dayjs(itm.stoppedTime).diff(dayjs(itm.startedTime), "seconds") %
              60
            }s`,
          }))
        );
      },
    }
  );
  const { run } = useRequest(
    async (link: string) => spaceStore.getDownloadLink({ link }),
    {
      loadingDelay: 300,
      manual: true,
      onSuccess: (model: any) => {
        window.open(model, "_blank")?.focus();
      },
    }
  );

  interface MemberDataType {
    date: string;
    link: string;
    name: string;
    duration: string;
  }
  const columns: ColumnsType<MemberDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, record) => <span>{name}</span>,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration, record) => <span>{duration}</span>,
    },
    {
      title: "Sharable link",
      dataIndex: "link",
      key: "link",
      render: (link, record) => (
        <a
          href={`https://edu.cake.work/r/${encodeURIComponent(link)}`}
          target="_blank"
          rel="noreferrer"
        >
          Open Link
        </a>
      ),
    },
    {
      title: "Download link",
      key: "link",
      dataIndex: "link",
      render: (_, { link }) => (
        <Tag
          color={"black"}
          onClick={async () => {
            run(link);
          }}
        >
          Download
        </Tag>
      ),
    },
  ];
  return (
    <Drawer
      title="Session recordings"
      width={820}
      onClose={props.onClose}
      open={props.visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={recordings}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default RoomRecordings;
