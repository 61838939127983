import { useEffect, useRef, useState } from "react";
import { DyteMeeting, provideDyteDesignSystem } from "@dytesdk/react-ui-kit";
import { useDyteClient } from "@dytesdk/react-web-core";
import styles from "./room.module.scss";
import { useStore } from "../../hooks/use-store";

interface DyteProps {
  token: string;
  theme: string;
}

const Room = ({ waitBeforeShow = 10, token, theme }: Props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(false);
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow, token]);

  return isShown ? <DyteRoom theme={theme} token={token} /> : null;
};

function DyteRoom({ token, theme }: DyteProps) {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const [meeting, initMeeting] = useDyteClient();
  const meetingEl = useRef();

  useEffect(() => {
    // provideDyteDesignSystem(node, designTokens)
    provideDyteDesignSystem(meetingEl.current, {
      fontFamily:
        'Outfit, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      logo: "https://assets.website-files.com/63bc3c90bcaff7274157f53a/63d12f390352b00a5dbb6590_Screen-app-2-p-500.jpg",
      colors: {
        brand: {
          300: "#5d21ff",
          400: "#5720ef",
          500: "#5d21ff",
          600: "#5d21ff",
          700: "#5d21ff",
        },
      },
      theme: theme,
      borderRadius: "extra-rounded",
    });

    const authToken = token;
    initMeeting({
      authToken,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meeting) {
      spaceStore.dyteRoom = meeting;
    }
  }, [meeting, spaceStore]);

  // By default this component will cover the entire viewport.
  // To avoid that and to make it fill a parent container, pass the prop:
  // `mode="fill"` to the component.
  return (
    <div className={styles.container}>
      <DyteMeeting ref={meetingEl} mode="fill" size="lg" meeting={meeting!} />
    </div>
  );
}

export default Room;
