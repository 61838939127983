import { Anchor, Paper, Title, Text, Container, Button } from "@mantine/core";

import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import styles from "./forgotPassword.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoLight from "../../../components/logoLight/LogoLight";

interface FormProps {
  email: string;
}

const ForgotPassword = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const [success, showSuccess] = useState(false);
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      email: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    const res = await authStore.forgotPassword(data.email);

    if (res.ok) {
      showSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <LogoLight />
        </div>
        <Title align="center" sx={(theme) => ({ fontWeight: 700 })}>
          Forgot your password? No worries!
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          <Link to={"/login"}>
            <Anchor size="sm" component="button">
              Login here
            </Anchor>
          </Link>
        </Text>

        {success ? (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <span>
              {t("forget.sent", {
                defaultValue: "Email has been sent. Please check your inbox",
              })}
            </span>
          </Paper>
        ) : (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <FormInput
              type="text"
              rules={{
                required: true,
              }}
              error={errors.email}
              control={control}
              label={t("forget.email", { defaultValue: "Enter email" })}
              name={"email"}
            />
            <span>
              {t("forget.will.send", {
                defaultValue: "We will send the instructions to your email",
              })}
            </span>
            <Button
              fullWidth
              mt="xl"
              loading={isSubmitting}
              onClick={handleSubmit(login)}
            >
              {t("forget.send.email", {
                defaultValue: "Send magic link to email",
              })}
            </Button>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default ForgotPassword;
