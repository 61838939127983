import React, { useEffect } from "react";
import { Layout } from "antd";

import Sidebar from "../../components/sidebar/sidebar";

import { observer } from "mobx-react";
import { useStore } from "../../hooks/use-store";
import FullscreenLoader from "../space/fullscreenLoader/Loader";
import ReconnectIndicator from "../space/reconnectIndicator/ReconnectIndicator";
import { Helmet } from "react-helmet";
import DyteRoom from "../../pages/experiment/DyteRoom";
import styles from "./space.module.scss";
import FooterSimple from "../../pages/public/profile/Footer";
import { Calendar } from "../../pages/public/profile/Calendar";
import Header from "../../pages/public/profile/Header";
import CopyToClipboard from "./CopyToClipboard";
import liveIcon from "./live.png";
import RoomControls from "./RoomControls";
import Cards from "./Cards";
import Faq from "./Faq";
import { createStyles } from "@mantine/core";
import { Route, Switch } from "react-router-dom";
import App from "./Chat";
import Editor from "./Editor";
import Pricing from "./Pricing";
import UpgradeModal from "./UpgradeModal";
import { TARIFFS } from "../../helpers/enums";

const { Content } = Layout;

const useStyles = createStyles((theme) => ({
  root: {
    height: "100%",
  },
  container: {
    width: "100%",
    height: "100%",
    background: theme.colorScheme === "dark" ? "#25262b" : "rgb(248, 249, 250)",
    overflowY: "auto",
  },
}));

const Space = (model: { spaceId: string }) => {
  const { classes } = useStyles();

  // const { spaceId } = useParams<{ spaceId: string }>();
  const {
    uiStore: { authStore },
    dataStore: { spaceStore },
  } = useStore();

  useEffect(() => {
    if (authStore.currentUser?.identityId) {
      console.log("connect", authStore.currentUser, model.spaceId);
      spaceStore.connect(
        authStore.currentUser!.identityId,
        model.spaceId,
        `${authStore.currentUser!.firstName} ${
          authStore.currentUser!.lastName
        }`,
        authStore.currentUser!.avatar,
        null
      );
    }

    return () => {
      (async () => await spaceStore.disconnect())();
    };
  }, [
    authStore.currentUser,
    authStore.currentUser?.identityId,
    model.spaceId,
    spaceStore,
  ]);

  if (spaceStore.spaceId && spaceStore.plan === TARIFFS.NONE) {
    return <UpgradeModal />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        {/* <script type="text/javascript">
          {`
        var eyelet_wdgt = { 'id':'6343dc5b2b521fe3cffcf086'};
        (function(d) {
          var n = d.getElementsByTagName('script')[0],
          s = d.createElement('script');
          s.async = true;
          s.src = '//widgetapp.eyelet.io/code.js';
          n.parentNode.insertBefore(s, n);
        })(document);
        `}
        </script> */}
      </Helmet>
      <FullscreenLoader />
      <ReconnectIndicator />
      <Content>
        <div className={classes.container}>
          <Header />
          <div className={styles.inner}>
            <Sidebar />

            {spaceStore.ready ? (
              spaceStore.currentRoom?.receptionRoom ? (
                <Switch>
                  <Route path="/space/chat">
                    <App />
                  </Route>
                  <Route path="/space/pricing">
                    <Pricing />
                  </Route>
                  <Route path="/space/editor">
                    <Editor />
                  </Route>
                  <Route path="/space">
                    <div className={styles.content}>
                      <div className={styles.articles}>
                        <div>
                          <Calendar />
                          <Cards />
                          {/* <ArticleCardFooter
                        image={"https://images.unsplash.com/photo-1477554193778-9562c28588c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80"}
                        category={"decorations"}
                        title={"Top 50 underrated plants for house decoration"}
                        footer={"733 people liked this"}
                        author={{
                          "name": "Elsa Gardenowl",
                          "description": "posted 34 minutes ago",
                          "image": "https://images.unsplash.com/photo-1628890923662-2cb23c2e0cfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=200&q=80"
                        }}

                      />
                      */}
                        </div>

                        <div>
                          <Faq />
                        </div>
                      </div>
                    </div>
                  </Route>
                </Switch>
              ) : spaceStore.streamToken ? (
                <div className={styles.meetingContainer}>
                  <div className={styles.roomHeader}>
                    <img src={liveIcon} alt="live" />
                    <div className={styles.grow}></div>
                    <CopyToClipboard
                      text={"Copy attendee link"}
                      link={`https://edu.cake.work/meeting/${spaceStore.currentRoom?.roomId}`}
                    />
                    <RoomControls />
                  </div>
                  <div className={styles.meeting}>
                    <DyteRoom
                      token={spaceStore.streamToken}
                      theme={spaceStore.currentRoom?.theme}
                    />
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          <FooterSimple
            links={[
              {
                label: "Cake App",
                link: "https://cake.work",
              },
            ]}
          />
        </div>

        {/* <Layout className="site-layout-background">
          <Sider
            collapsedWidth={22}
            collapsed={spaceStore.spaceSidebarCollapsed}
            width={320}
          >
            <Sidebar />
          </Sider>

          <RoomHeader />
          <Content
            style={{ minHeight: 280, height: "100vh", position: "relative" }}
          >
            {spaceStore.ready ? (
              spaceStore.currentRoom?.receptionRoom ? (
                <div className={styles.reception}>
                  <span>Soon. Public dashboard for you members</span>
                  <img alt="cake" src="/main.png" />
                </div>
              ) : spaceStore.streamToken ? (
                <DyteRoom
                  token={spaceStore.streamToken}
                  theme={spaceStore.currentRoom?.theme}
                />
              ) : null
            ) : null}
          </Content>
        </Layout> */}
      </Content>
    </div>
  );
};

export default observer(Space);
