import { Button, rem, Tooltip } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { IconCopy, IconCheck } from "@tabler/icons-react";

interface IProps {
  text: string;
  link: string;
}
const CopyToClipboard = ({ text, link }: IProps) => {
  const clipboard = useClipboard();
  return (
    <Tooltip
      label="Link copied!"
      offset={5}
      position="bottom"
      radius="md"
      transitionProps={{ duration: 100, transition: "slide-down" }}
      opened={clipboard.copied}
    >
      <Button
        variant="light"
        rightIcon={
          clipboard.copied ? (
            <IconCheck size="1.2rem" stroke={1.5} />
          ) : (
            <IconCopy size="1.2rem" stroke={1.5} />
          )
        }
        radius="xl"
        size="sm"
        styles={{
          root: { paddingRight: rem(14), height: rem(32) },
          rightIcon: { marginLeft: rem(8) },
        }}
        onClick={() => clipboard.copy(link)}
      >
        {text}
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
