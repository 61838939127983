import React, { useEffect } from "react";

import {
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";

import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import styles from "./register.module.scss";
import FormInput from "../../../components/form/input";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoLight from "../../../components/logoLight/LogoLight";

interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const Register = () => {
  const {
    uiStore: { authStore },
  } = useStore();

  const { t } = useTranslation();

  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });
  }, [reset]);

  const register = async (data: FormProps) => {
    const res = await authStore.register({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    });
    if (res.ok) {
      // @ts-ignore
      window.location = "/space";
    }
  };
  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <LogoLight />
        </div>
        <Title
          align="center"
          sx={(theme) => ({
            fontWeight: 900,
          })}
        >
          Welcome to Cake EDU!
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          {t("registerpage.already.have", {
            defaultValue: "Already have an account?",
          })}{" "}
          <Link to={"/login"}>
            <Anchor size="sm" component="button">
              Login here
            </Anchor>
          </Link>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <FormInput
            type="text"
            rules={{
              required: true,
            }}
            placeholder="Alain"
            error={errors.firstName}
            control={control}
            label={t("registerpage.first.name", { defaultValue: "First name" })}
            name={"firstName"}
          />
          <FormInput
            type="text"
            rules={{
              required: true,
            }}
            placeholder="Prost"
            error={errors.lastName}
            control={control}
            label={t("registerpage.last.name", { defaultValue: "Last name" })}
            name={"lastName"}
          />
          <FormInput
            type="email"
            placeholder="e@cake.work"
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            }}
            error={errors.email}
            control={control}
            label={t("registerpage.email", { defaultValue: "Email" })}
            name={"email"}
          />
          <FormInput
            type="password"
            rules={{
              required: true,
            }}
            placeholder="*******"
            error={errors.password}
            control={control}
            label={t("registerpage.password", { defaultValue: "Password" })}
            name={"password"}
          />

          {/* <br />
        <br />
        <FormButton loading={isSubmitting} onClick={handleSubmit(register)}>
          {t("registerpage.create.button", {
            defaultValue: "Create free account",
          })}
        </FormButton> */}
          {/* <PasswordInput label="Password" placeholder="Your password" required mt="md" /> */}
          <Group position="apart" mt="lg">
            <Checkbox
              label={
                <span>
                  {t("registerpage.agree", {
                    defaultValue: "By signing up, i have read and agreed",
                  })}{" "}
                  <a
                    href={"https://go.cake.work/terms"}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {t("registerpage.terms", { defaultValue: "Terms" })}
                  </a>{" "}
                  {t("registerpage.and", { defaultValue: "and" })}{" "}
                  <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={"https://go.cake.work/privacy"}
                  >
                    {t("registerpage.conditions", {
                      defaultValue: "Conditions",
                    })}
                  </a>
                </span>
              }
            />
            {/* <Anchor component="button" size="sm">
            Forgot password?
          </Anchor> */}
          </Group>
          <Button
            fullWidth
            mt="xl"
            loading={isSubmitting}
            onClick={handleSubmit(register)}
          >
            Create account
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default Register;
