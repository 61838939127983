/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import { Modal, Checkbox } from "antd";
import EditSpaceDrawer from "./editSpace/EditSpace";

import { createStyles, Navbar, rem, getStylesRef } from "@mantine/core";
import LogoLight from "../logoLight/LogoLight";
import Sessions from "./Sessions";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import QuickLinks from "./QuickLinks";
import Home from "./Home";
import Settings from "./Settings";

const useStyles = createStyles((theme) => ({
  navbar: {
    borderLeft: "none",
    paddingTop: 0,
    zIndex: 0,
    height: "100%",
    minHeight: `calc(100vh - 60px)`,
    position: "initial",
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: theme.spacing.md,

    "&:not(:last-of-type)": {
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
    },
  },
  footerSection: {
    marginTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  grow: {
    flexGrow: 1,
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.md,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `${rem(8)} ${theme.spacing.md}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.fontSizes.md,
    flex: 1,
    fontWeight: 600,
    fontFamily: `Outfit, ${theme.fontFamily}`,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.xs,
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: "none",
  },

  collections: {
    paddingLeft: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingRight: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: `calc(${theme.spacing.md} + ${rem(2)})`,
    paddingRight: theme.spacing.md,
    marginBottom: rem(5),
  },

  collectionSectionName: {
    fontFamily: `Outfit, ${theme.fontFamily}`,
  },

  addIcon: {
    display: "flex",
    alignItems: "center",
    opacity: 0.8,
  },

  collectionLink: {
    display: "block",
    padding: `${rem(8)} ${theme.spacing.xs}`,
    textDecoration: "none",
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  footer: {
    // paddingTop: theme.spacing.md,
    // marginTop: theme.spacing.md,
    width: "100%",
    padding: "0.625rem 1.3rem",
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  linkIcon: {
    marginLeft: "6px",
    width: "63px",
    height: "20px",
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.xs,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      [`& .${getStylesRef("icon")}`]: {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  },
}));

function Sidebar() {
  const { classes } = useStyles();

  const {
    dataStore: { spaceStore },
  } = useStore();

  const [spaceEditModalOpen, setSpaceEditModalOpen] = useState<null | {
    name?: string;
    logo?: string;
  }>(null);

  const changeRoom = async (roomId: string) => {
    if (spaceStore.currentRoom?.receptionRoom) {
      spaceStore.changeRoom(roomId);
    } else {
      const showConfirm = window.localStorage.getItem("confirmOnRoomChange");
      if (!showConfirm) {
        Modal.confirm({
          onOk: async () => {
            spaceStore.changeRoom(roomId);
          },
          onCancel: () => {},
          okText: "Onward!",
          cancelText: "Stay here",
          title: "Hey, you are about to leave this room.",
          content: (
            <div>
              <p>
                People in this room will no longer see or hear you, until you
                return.
              </p>
              <Checkbox
                onChange={() => {
                  window.localStorage.setItem("confirmOnRoomChange", "true");
                }}
              >
                Don't ask again
              </Checkbox>
            </div>
          ),
        });
      } else {
        spaceStore.changeRoom(roomId);
      }
    }
  };

  return (
    <Navbar
      width={{ sm: 300 }}
      pt="md"
      // pl="md"
      // pr="md"
      className={classes.navbar}
    >
      <Home
        onClick={() => {
          changeRoom(spaceStore.getReceptionRoomId());
        }}
      />
      <Settings
        onClick={() => {
          setSpaceEditModalOpen({
            name: spaceStore.spaceName,
            logo: spaceStore.logo ? spaceStore.logo : undefined,
          });
        }}
      />
      <Sessions />
      {/* <QuickLinks /> */}
      {/* <Navbar.Section className={classes.section}>
        <div className={classes.mainLinks}>
          <UnstyledButton
            key={"home"}
            className={classes.mainLink}
            onClick={() => {
              changeRoom(spaceStore.getReceptionRoomId());
            }}
          >
            <IconHome size={20} className={classes.mainLinkIcon} stroke={1.5} />
            <div className={classes.mainLinkInner}>
              <span>Home</span>
            </div>
          </UnstyledButton>
          <UnstyledButton
            key={"settings"}
            className={classes.mainLink}
            onClick={() => {
              setSpaceEditModalOpen({
                name: spaceStore.spaceName,
                logo: spaceStore.logo ? spaceStore.logo : undefined,
              });
            }}
          >
            <IconSettings
              size={20}
              className={classes.mainLinkIcon}
              stroke={1.5}
            />
            <div className={classes.mainLinkInner}>
              <span>Settings</span>
            </div>
          </UnstyledButton>
        </div>
      </Navbar.Section> */}

      {/* <Navbar.Section className={classes.section}>
        <Group className={classes.collectionsHeader} position="apart">
          <Text
            size="sm"
            weight={600}
            color="dimmed"
            className={classes.collectionSectionName}
          >
            Sessions
          </Text>
      
          <ActionIcon
            variant="default"
            size={20}
            className={classes.addIcon}
            onClick={() => {
              setAddRoomDrawerOpen(true);
            }}
          >
        
            <IconPlus size="0.8rem" stroke={1.5} />
          </ActionIcon>
      
        </Group>
        <div className={classes.collections}>
          {spaceStore.rooms
            .filter((itm) => !itm.hidden)
            .filter((itm) => !itm.receptionRoom)
            .map((itm) => {
              const roomParticipants = participants.filter(
                (p) => p.roomId === itm.roomId
              );

              return (
                <div
                  key={itm.roomId}
                  className={styles.roomItem}
                  onClick={() => {
                    if (itm.roomId !== spaceStore.currentRoom?.roomId) {
                      spaceStore.canJoinOtherRoom
                        ? changeRoom(itm.roomId)
                        : console.log("noo");
                    }
                  }}
                  style={
                    spaceStore.currentRoom?.roomId === itm.roomId
                      ? { background: "#f8f9fa" }
                      : {}
                  }
                >
                  <div className={styles.roomNameContainer}>
                    <div className={styles.roomName}>{itm.name}</div>
                    <div className={styles.roomCounter}>
                      <span>
                        <Pluralize
                          singular={"user"}
                          count={roomParticipants.length}
                          zero={"no users"}
                        />
                      </span>
                    </div>
                  </div>
                  {roomParticipants.length > 0 ? (
                    <div className={styles.roomParticipantContainer}>
                      <Avatar.Group
                        maxCount={8}
                        maxStyle={{
                          color: "white",
                          backgroundColor: "#100b18",
                          borderColor: "#100b18",
                        }}
                      >
                        {roomParticipants.map((rp) => (
                          <Fragment key={rp.identityId}>
                            <Tooltip title={rp.username} arrowPointAtCenter>
                              <Avatar
                                //shape="square"

                                style={{
                                  border: "none",
                                  backgroundColor: "#272b31",
                                  cursor: "pointer",
                                }}
                                size={32}
                                src={rp.avatar}
                              >
                                {getInitials(rp.username)}
                              </Avatar>
                            </Tooltip>
                          </Fragment>
                        ))}
                      </Avatar.Group>
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </Navbar.Section> */}
      <div className={classes.grow}></div>
      <Navbar.Section className={classes.footerSection}>
        <div className={classes.footer}>
          <a
            href="https://www.cake.work"
            className={classes.link}
            onClick={(event) => event.preventDefault()}
          >
            <span>Proudly Baked By </span>
            <div className={classes.linkIcon}>
              <LogoLight />
            </div>
          </a>
        </div>
      </Navbar.Section>

      {/* <EditRoomDrawer
        visible={!!editRoomDrawerData}
        onClose={() => setEditRoomDrawerData(null)}
        room={editRoomDrawerData}
      />
      <InviteToRoom
        visible={!!inviteRoomDrawerData}
        onClose={() => setInviteRoomDrawerData(null)}
        room={inviteRoomDrawerData}
      /> */}

      {/* <AddRoom
        visible={addRoomDrawerOpen}
        onClose={() => setAddRoomDrawerOpen(false)}
      /> */}

      {/* {roomRecordingsDrawerOpen ? (
        <RoomRecordings
          visible={!!roomRecordingsDrawerOpen}
          roomId={roomRecordingsDrawerOpen}
          onClose={() => setRoomRecordingsDrawerOpen(null)}
        />
      ) : null} */}

      <EditSpaceDrawer
        visible={!!spaceEditModalOpen}
        onClose={() => setSpaceEditModalOpen(null)}
        initialData={spaceEditModalOpen!}
        submit={async (model) => {
          await spaceStore.updateSpace(model);
          setSpaceEditModalOpen(null);
        }}
      />
    </Navbar>
  );
}

export default observer(Sidebar);
