import {
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";

import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useStore } from "../../../hooks/use-store";
import FormInput from "../../../components/form/input";
import styles from "./login.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoLight from "../../../components/logoLight/LogoLight";

interface FormProps {
  username: string;
  password: string;
}

const Login = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const {
    control,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormProps>();

  useEffect(() => {
    reset({
      username: "",
      password: "",
    });
  }, [reset]);

  const login = async (data: FormProps) => {
    await authStore.login(data.username, data.password);
  };

  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <LogoLight />
        </div>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Hello Again!
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          No account?{" "}
          <Link to={"/new-account"}>
            <Anchor size="sm" component="button">
              Create here
            </Anchor>
          </Link>
        </Text>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <FormInput
            type="text"
            rules={{
              required: true,
            }}
            error={errors.username}
            control={control}
            label={t("loginpage.email")}
            name={"username"}
          />
          <FormInput
            type="password"
            rules={{
              required: true,
            }}
            error={errors.password}
            control={control}
            label={t("loginpage.password", { defaultValue: "Password" })}
            name={"password"}
          />
          <Group position="apart" mt="lg">
            <Link to={"/forgot-password"}>
              <Anchor component="button" size="sm">
                Forgot password?
              </Anchor>
            </Link>
          </Group>
          <Button
            fullWidth
            mt="xl"
            loading={isSubmitting}
            onClick={handleSubmit(login)}
          >
            Sign in
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
