/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRequest } from "ahooks";
import { Card, Space } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { useState } from "react";

import { useParams } from "react-router-dom";
import Logo from "../../../components/Logo";
import { useStore } from "../../../hooks/use-store";
import styles from "./publicRecording.module.scss";

const PublicRecording = () => {
  const {
    dataStore: { appStore },
  } = useStore();

  const [recordingData, setRecordingData] = useState<any>({});
  const { fileName } = useParams<{ fileName: string }>();

  const { loading } = useRequest(
    async () => appStore.getPublicRecordingByFilename({ fileName }),
    {
      loadingDelay: 300,
      onSuccess: (model) => {
        setRecordingData(model);
      },
    }
  );
  const { run } = useRequest(
    async () => appStore.getDownloadLink({ fileName }),
    {
      manual: true,
      onSuccess: (model: any) => {
        window.open(model, "_blank")?.focus();
      },
    }
  );

  return (
    <div className={styles.container}>
      <Space direction="vertical">
        <div className={styles.logo}>
          <Logo />
        </div>
        <Card
          loading={loading}
          size="small"
          title={recordingData.name}
          extra={
            <a href="#" onClick={run}>
              Download
            </a>
          }
          style={{ width: 400 }}
        >
          <p>
            Duration:{" "}
            {`${dayjs(recordingData.stoppedTime).diff(
              dayjs(recordingData.startedTime),
              "hours"
            )}h, ${
              dayjs(recordingData.stoppedTime).diff(
                dayjs(recordingData.startedTime),
                "minutes"
              ) % 60
            }min, ${
              dayjs(recordingData.stoppedTime).diff(
                dayjs(recordingData.startedTime),
                "seconds"
              ) % 60
            }s`}
          </p>
          <p>
            Recorded at: {dayjs(recordingData.stoppedTime).fromNow(true)} ago
          </p>
        </Card>
      </Space>
    </div>
  );
};

export default observer(PublicRecording);
