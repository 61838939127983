import React from "react";
import FormButton from "../../../components/form/button";
import styles from "./spaceWelcomeScreen.module.scss";
import Countdown from "react-countdown";

import {
  createStyles,
  Card,
  Image,
  Group,
  Text,
  Avatar,
  rem,
} from "@mantine/core";

interface Props {
  spaceId: string | undefined;
  spaceName: string | undefined;
  logo: string | undefined;
  welcomePage:
    | {
        enabled: boolean;
        date: string;
        name: string;
        description: string;
        background: string;
      }
    | undefined;
  onSuccess: () => void;
}

const SpaceWelcomeScreen = ({
  onSuccess,
  spaceName,
  welcomePage,
  logo,
}: Props) => {
  const date = welcomePage?.date ? new Date(welcomePage?.date) : new Date();

  // import { IconHeart, IconBookmark, IconShare } from '@tabler/icons-react';

  const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    },

    title: {
      fontFamily: `Outfit, ${theme.fontFamily}`,
    },
    description: {
      // fontFamily: `Outfit, ${theme.fontFamily}`,
    },

    footer: {
      display: "flex",
      justifyContent: "center",
      padding: `${theme.spacing.xs} ${theme.spacing.lg}`,
      marginTop: theme.spacing.md,
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[2]
      }`,
    },
  }));

  const { classes } = useStyles();

  return (
    <div className={styles.page}>
      <Card withBorder padding="lg" radius="md" className={classes.card}>
        <Card.Section mb="sm">
          <Image
            src={welcomePage?.background || "/backgrounds/default/bg3.jpg"}
            alt={"title"}
            height={180}
          />
        </Card.Section>

        {/* <Badge>{category}</Badge> */}

        {/* <Text size={"xl"} fw={700} className={classes.title} mt="xs">
          Cake invites you to {spaceName}
        </Text> */}

        <Text fz="sm" mt="xs" size={"sm"} className={classes.description}>
          <div className={styles.eventName}>
            <span>{welcomePage?.name}</span>
          </div>
          <div className={styles.countdown}>
            <Countdown
              date={date}
              renderer={({ days, hours, minutes, seconds, completed }: any) => {
                if (completed) {
                  // Render a complete state
                  return (
                    <FormButton onClick={onSuccess}>Join event</FormButton>
                  );
                } else {
                  // Render a countdown
                  return (
                    <>
                      <div className={styles.countdownText}>
                        <span className={styles.startingIn}>Starting in:</span>
                        <span>
                          {days}d : {hours}h : {minutes}m : {seconds}s
                        </span>
                      </div>
                    </>
                  );
                }
              }}
            />
          </div>
        </Text>
        <Group mt="lg">
          <Avatar src={logo} radius="sm" />
          <div>
            <Text fw={500}>Invite from {spaceName}</Text>
            {/* <Text fz="xs" c="dimmed">
              {'author.description'}
            </Text> */}
          </div>
        </Group>
        <Text size={"md"} fw={700} className={classes.title} mt="xs">
          <div className={styles.eventDescription}>
            {welcomePage?.description || "Nothing about this space for now"}
          </div>
        </Text>

        <Card.Section className={classes.footer}>
          <Group position="apart">
            <Text fz="xs" c="dimmed">
              <a
                href={"https://www.cake.work"}
                rel="noreferrer"
                target={"_blank"}
              >
                <span>Powered by Cake</span>
              </a>
            </Text>
          </Group>
        </Card.Section>
      </Card>
    </div>
  );
};

export default SpaceWelcomeScreen;
