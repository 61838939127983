import React from "react";
import { Button as MantineButton } from "@mantine/core";

const FormButton = (props: any) => {
  return (
    <MantineButton {...props} w={"100%"} size={props.size || "md"}>
      {props.children}
    </MantineButton>
  );
};

export default FormButton;
