import {
  createStyles,
  Header,
  Menu,
  Group,
  Text,
  Burger,
  Container,
  rem,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useRequest } from "ahooks";
import { Image, Avatar } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import EditAccount from "../../../components/sidebar/editAccount/EditAccount";
import EditSpaceDrawer from "../../../components/sidebar/editSpace/EditSpace";
import { TARIFFS } from "../../../helpers/enums";
import { getInitials } from "../../../helpers/helper";
import { useStore } from "../../../hooks/use-store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LanguageSwitcher from "./LanguageSwitcher";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ThemeToggler from "./ThemeToggler";
// import LanguageSwitcher from "./LanguageSwitcher";
// import ThemeToggler from "./ThemeToggler";

const useStyles = createStyles((theme) => ({
  header: {
    maxWidth: "100%",

    width: "100%",
    margin: "0 auto",
  },
  inner: {
    height: rem(60),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  left: {
    display: "flex",
    alignItems: "center",
  },

  name: {
    fontWeight: 600,
    fontSize: theme.fontSizes.lg,
    marginLeft: "6px",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
  },
  logo: {
    height: rem(40),
    width: rem(100),
    display: "flex",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },

  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));

const HeaderMenu = () => {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const [editAccountDrawerOpen, setEditAccountDrawerOpen] = useState(false);

  const [spaceEditModalOpen, setSpaceEditModalOpen] = useState<null | {
    name?: string;
    logo?: string;
  }>(null);

  const {
    dataStore: { spaceStore },
    uiStore: { authStore },
  } = useStore();

  const { run } = useRequest(async () => spaceStore.getCustomerPortalLink(), {
    manual: true,
    onSuccess: (model: any) => {
      window.open(model.link, "_blank")?.focus();
    },
  });

  return (
    <Header height={60}>
      <Container className={classes.header}>
        <div className={classes.inner}>
          <div className={classes.left}>
            {spaceStore.logo ? (
              <Image
                preview={false}
                height={"auto"}
                width={"auto"}
                style={{
                  borderRadius: "6px",
                  maxHeight: "48px",
                  objectFit: "contain",
                }}
                alt="space logo"
                src={spaceStore.logo}
              />
            ) : null}

            <span className={classes.name}>{spaceStore.spaceName}</span>
          </div>
          <Group spacing={5} className={classes.links}>
            {/* <ThemeToggler />
            <LanguageSwitcher /> */}
            <Menu
              width={190}
              position="bottom-end"
              transitionProps={{ transition: "pop-top-right" }}
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
              withinPortal
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, {
                    [classes.userActive]: userMenuOpened,
                  })}
                >
                  <Group spacing={7}>
                    <Avatar
                      //shape="square"
                      style={{
                        border: "none",
                        backgroundColor: "#272b31",
                        cursor: "pointer",
                      }}
                      size={32}
                      src={authStore.currentUser?.avatar}
                    >
                      {getInitials(
                        authStore.currentUser?.firstName +
                          " " +
                          authStore.currentUser?.lastName
                      )}
                    </Avatar>

                    <Text weight={500} size="md" sx={{ lineHeight: 1 }} mr={3}>
                      {authStore.currentUser?.firstName}{" "}
                      {authStore.currentUser?.lastName}
                    </Text>
                    <IconChevronDown size={rem(12)} stroke={1.5} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Settings</Menu.Label>
                <Menu.Item
                  icon={null}
                  onClick={() => {
                    setEditAccountDrawerOpen(true);
                  }}
                >
                  Account settings
                </Menu.Item>
                {spaceStore.plan !== TARIFFS.FREE ? (
                  <Menu.Item
                    icon={null}
                    onClick={() => {
                      run();
                    }}
                  >
                    Manage subscription
                  </Menu.Item>
                ) : null}
                {/* <Menu.Item icon={null}>
                  Change account
                </Menu.Item> */}
                <Menu.Item
                  icon={null}
                  onClick={() => {
                    authStore.logout();
                    window.location.href = "/login";
                  }}
                >
                  Logout
                </Menu.Item>

                {/* <Menu.Divider />

              <Menu.Label>Danger zone</Menu.Label>
              <Menu.Item icon={null}>
                Pause subscription
              </Menu.Item>
              <Menu.Item color="red" icon={null}>
                Delete account
              </Menu.Item> */}
              </Menu.Dropdown>
            </Menu>
          </Group>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />
        </div>
      </Container>

      <EditAccount
        visible={editAccountDrawerOpen}
        onClose={() => setEditAccountDrawerOpen(false)}
      />

      <EditSpaceDrawer
        visible={!!spaceEditModalOpen}
        onClose={() => setSpaceEditModalOpen(null)}
        initialData={spaceEditModalOpen!}
        submit={async (model) => {
          await spaceStore.updateSpace(model);
          setSpaceEditModalOpen(null);
        }}
      />
    </Header>
  );
};

export default observer(HeaderMenu);
