import { action, computed, makeObservable, observable } from "mobx";
import { io, Socket } from "socket.io-client";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import Participant from "./participant";
import { CONNECTION_STATUS, ROLE } from "../../../types";
import { ROOM_THEME, ROOM_TYPE, TARIFFS } from "../../../helpers/enums";

export type Room = {
  roomId: string;
  name: string;
  background: string;
  isCustomBackground: boolean;
  receptionRoom: boolean;
  hidden: boolean;
  theme: string;
  password: string | null;
  welcomePageEnabled: boolean;
  welcomePageName: string;
  welcomePageDescription: string;
  welcomePageDate: string;
  welcomePageBackground: string | null;
};
export type Slide = {
  plotId: string;
  id: string;
  name: string;
  theme: string;
  updatedAt: string;
};

// const EXPERIMENT_ROOM_ID = "eEJ9fWfJ5E2iGiJ02k8YR";

export class SpaceStore {
  socket: Socket;
  spaceId: string;

  @observable spaceName: string;
  @observable logo: string | null;
  @observable ownerIdentity: string;
  @observable seats: number;
  @observable plan: TARIFFS;
  @observable trialEndsAt: Date;
  @observable theme: string;
  @observable identity: string;
  @observable role: ROLE;
  @observable ready = false;
  @observable dyteRoom: any;

  @observable participantMap: Map<string, Participant> = new Map();
  @observable streamToken: string;
  @observable rooms: Room[] = [];
  @observable slides: Slide[] = [];
  @observable currentRoom: Room | undefined;
  @observable soundNotify: boolean = false;

  @observable spaceSidebarCollapsed: boolean = false;
  @observable connectionStatus: CONNECTION_STATUS = CONNECTION_STATUS.NORMAL;

  @observable isInviteModalOpen: boolean = false;
  // @observable experimental3dRoom: boolean = false;

  @observable recordingStarted: boolean = false;

  constructor() {
    makeObservable(this);
  }

  async disconnect() {
    if (this.ready) {
      this.socket.disconnect();
    }
  }

  async connectSocket(model: {
    spaceId: string;
    username: string;
    identity: string;
    avatar: string | null;
    roomId: string | null;
  }) {
    this.socket.emit(
      "join",
      {
        spaceId: model.spaceId,
        username: model.username,
        identityId: model.identity,
        avatar: model.avatar,
        roomId: model.roomId,
      },
      (res: {
        error?: string;
        slides: Slide[];
        token: string;
        // agoraToken: string;
        user: {
          role: ROLE;
          visibleRoomsIds: null | string[];
        };
        space: {
          logo: string | null;
          name: string;
          ownerIdentity: string;
          seats: number;
          plan: TARIFFS;
          trialEndsAt: Date;
        };
        state: {
          join: {
            roomId: string;
          };
          rooms: Room[];
          participants: {
            avatar: string;
            connectionId: string;
            identityId: string;
            username: string;
            roomId: string;
            role: ROLE;
          }[];
        };
      }) => {
        if (res.error === "permissionDenied") {
          // @ts-ignore
          window.location = "/forbidden";
        }
        // if (res.error === "userNotFound") {
        //   // @ts-ignore
        //   window.location = "/forbidden";
        // }
        if (res.error === "spaceNotFound") {
          // @ts-ignore
          window.location = "/forbidden";
        }

        this.ready = true;
        this.role = res.user.role;
        this.seats = res.space.seats;
        this.plan = res.space.plan;
        this.trialEndsAt = res.space.trialEndsAt;
        this.ownerIdentity = res.space.ownerIdentity;
        this.spaceName = res.space.name;
        this.logo = res.space.logo;
        this.streamToken = res.token;
        this.rooms = res.state.rooms;

        const currentRoom = res.state.rooms.find(
          (itm) => itm.roomId === res.state.join.roomId
        );
        if (currentRoom) {
          this.currentRoom = currentRoom;
        }

        for (const participant of res.state.participants) {
          this.participantMap.set(
            participant.identityId,
            new Participant({
              avatar: participant.avatar,
              username: participant.username,
              identityId: participant.identityId,
              roomId: participant.roomId,
              role: participant.role,
            })
          );
        }
      }
    );
  }

  async connect(
    identity: string,
    spaceId: string,
    username: string,
    avatar: string | null,
    roomId: string | null
  ) {
    this.ready = false;
    this.spaceId = spaceId;
    this.identity = identity;

    if (roomId) {
      this.currentRoom = {
        roomId: roomId,
        name: "",
        background: "",
        isCustomBackground: false,
        receptionRoom: false,
        hidden: false,
        theme: "dark",

        password: null,
        welcomePageEnabled: false,
        welcomePageName: "",
        welcomePageDescription: "",
        welcomePageDate: "",
        welcomePageBackground: null,
      };
    }
    this.socket = io(getEnv().envConfig.api_end_point, {
      path: "/space/socket.io",
      transports: ["websocket"],
    });

    this.socket.io.on("open", async () => {
      this.connectionStatus = CONNECTION_STATUS.NORMAL;

      await this.connectSocket({
        identity: this.identity,
        spaceId: this.spaceId,
        username,
        avatar,
        roomId: this.currentRoom?.roomId || null,
      });
    });

    this.socket.on("connect_error", async () => {
      this.connectionStatus = CONNECTION_STATUS.ERROR;
    });

    this.socket.io.on("reconnect_attempt", async () => {
      this.connectionStatus = CONNECTION_STATUS.RECONNECTING;
    });

    this.socket.on("disconnect", (reason: any) => {
      console.log("disconnect", reason);
      if (reason === "io server disconnect") {
        // TODO show errors duplicate identity
        //@ts-ignore
        window.location = "/kicked";
      }
    });

    this.socket.io.on("error", async () => {
      this.connectionStatus = CONNECTION_STATUS.ERROR;
    });

    this.socket.io.on("reconnect", async () => {
      this.connectionStatus = CONNECTION_STATUS.NORMAL;
    });

    this.socket.on("memberDisconnected", (data: { identityId: string }) => {
      console.log("memberDisconnected", data);
      this.participantMap.delete(data.identityId);
    });

    this.socket.on("slideAdded", (data: Slide) => {
      this.slides.push(data);
    });

    this.socket.on("slideDeleted", (data: { plotId: string }) => {
      this.slides = this.slides.filter((itm) => itm.plotId !== data.plotId);
    });

    this.socket.on(
      "memberConnected",
      (data: {
        connectionId: string;
        spaceId: string;
        username: string;
        identityId: string;
        avatar: string;
        roomId: string;
        role: ROLE;
      }) => {
        console.log("memberConnected", data);
        this.participantMap.set(
          data.identityId,
          new Participant({
            avatar: data.avatar,
            username: data.username,
            identityId: data.identityId,
            roomId: data.roomId,
            role: data.role,
          })
        );

        if (data.roomId === this.currentRoom?.roomId) {
          this.soundNotify = true;
        }
      }
    );

    this.socket.on(
      "memberUpdated",
      (data: {
        connectionId: string;
        participant: {
          username: string;
          identityId: string;
          avatar: string;
          roomId: string;
        };
      }) => {
        const participant = this.participantMap.get(
          data.participant.identityId
        );
        if (participant) {
          participant.roomId = data.participant.roomId;

          if (data.participant.roomId === this.currentRoom?.roomId) {
            this.soundNotify = true;
          }
        }
      }
    );
    this.socket.on(
      "roomUpdated",
      (data: {
        roomId: string;
        background: string;
        isCustomBackground: boolean;
        name: string;
        theme: string;
        password: string | null;
        welcomePageEnabled: boolean;
        welcomePageName: string;
        welcomePageDescription: string;
        welcomePageDate: string;
        welcomePageBackground: string | null;
      }) => {
        if (this.currentRoom!.roomId === data.roomId) {
          this.currentRoom = {
            ...this.currentRoom!,
            ...data,
          };
        }

        this.rooms = this.rooms.map((r) => {
          if (r.roomId === data.roomId) {
            return {
              ...r,
              ...data,
            };
          } else {
            return r;
          }
        });
      }
    );

    this.socket.on("roomDeleted", (data: { roomId: string }) => {
      this.rooms = this.rooms.filter((itm) => itm.roomId !== data.roomId);
    });

    this.socket.on("spaceUpdated", (data: { name: string; logo: string }) => {
      this.spaceName = data.name;
      this.logo = data.logo;
    });

    this.socket.on(
      "roomAdded",
      (data: {
        roomId: string;
        name: string;
        background: string;
        isCustomBackground: boolean;
        receptionRoom: boolean;
        theme: string;
        password: string | null;
        welcomePageEnabled: boolean;
        welcomePageName: string;
        welcomePageDescription: string;
        welcomePageDate: string;
        welcomePageBackground: string | null;
      }) => {
        const hidden = this.role === ROLE.GUEST;
        this.rooms = [
          ...this.rooms,
          {
            roomId: data.roomId,
            name: data.name,
            background: data.background,
            isCustomBackground: data.isCustomBackground,
            receptionRoom: data.receptionRoom,
            theme: data.theme,
            hidden,
            password: data.password,
            welcomePageEnabled: data.welcomePageEnabled,
            welcomePageName: data.welcomePageName,
            welcomePageDescription: data.welcomePageDescription,
            welcomePageDate: data.welcomePageDate,
            welcomePageBackground: data.welcomePageBackground,
          },
        ];
      }
    );
    this.socket.on(
      "participantUpdated",
      (data: {
        identityId: string;
        username: string;
        avatar: string;
        role?: ROLE;
      }) => {
        const participant = this.participantMap.get(data.identityId);
        if (participant) {
          this.participantMap.set(
            data.identityId,
            new Participant({
              roomId: participant.roomId,
              identityId: participant.identityId,
              username: data.username,
              avatar: data.avatar,
              role: data.role || participant.role,
            })
          );

          if (data.identityId === this.identity) {
            this.role = data.role || participant.role;

            if (data.role === ROLE.COLLABORATOR || data.role === ROLE.ADMIN) {
              this.rooms = this.rooms.map((itm) => ({
                ...itm,
                hidden: false,
              }));
            }
          }

          if (participant.roomId === this.currentRoom?.roomId) {
            this.soundNotify = true;
          }
        }
      }
    );
  }

  @computed
  get participants(): Participant[] {
    return Array.from(this.participantMap.values());
  }

  getByIdentity = (identityId: string) => {
    return this.participantMap.get(identityId);
  };

  // setAgoraClient = (client: IAgoraRTCClient) => {
  //   this.agoraClient = client;
  // };

  @action
  changeRoom = async (roomId: string) => {
    await this.dyteRoom?.leaveRoom();

    // check localstorage
    const localParticipant = this.participantMap.get(this.identity);
    if (localParticipant) {
      localParticipant.roomId = roomId;
    }
    this.socket.emit(
      "changeRoom",
      {
        spaceId: this.spaceId,
        roomId: roomId,
        identityId: this.identity,
      },
      (res: { token: string }) => {
        this.streamToken = res.token;
      }
    );

    const currentRoom = this.rooms.find((itm) => itm.roomId === roomId);
    if (currentRoom) {
      // this.background = currentRoom.background;
      this.currentRoom = currentRoom;
      // this.experimental3dRoom = currentRoom.roomId === EXPERIMENT_ROOM_ID;
    }

    this.soundNotify = true;
  };

  updateRoom = async (model: {
    roomId: string;
    name: string;
    background: string;
    isCustomBackground: boolean;

    password: string | null;
    welcomePageEnabled: boolean;
    welcomePageName: string;
    welcomePageDescription: string;
    welcomePageDate: string;
    welcomePageBackground: string | null;
  }) => {
    this.socket.emit(
      "updateRoom",
      {
        spaceId: this.spaceId,
        roomId: model.roomId,
        name: model.name,
        background: model.background,
        isCustomBackground: model.isCustomBackground,
        password: model.password,
        welcomePageEnabled: model.welcomePageEnabled,
        welcomePageName: model.welcomePageName,
        welcomePageDescription: model.welcomePageDescription,
        welcomePageDate: model.welcomePageDate,
        welcomePageBackground: model.welcomePageBackground,
      },
      () => {}
    );
  };

  updateSpace = async (model: { name: string; logo: string | null }) => {
    this.socket.emit(
      "updateSpace",
      {
        spaceId: this.spaceId,
        name: model.name,
        logo: model.logo,
      },
      () => {}
    );
  };

  deleteRoom = async (model: { roomId: string }) => {
    this.socket.emit(
      "deleteRoom",
      {
        spaceId: this.spaceId,
        roomId: model.roomId,
      },
      () => {}
    );
  };

  kick = async (model: { identityId: string }) => {
    this.socket.emit(
      "kick",
      {
        spaceId: this.spaceId,
        identityId: model.identityId,
      },
      () => {}
    );
  };

  addRoom = async (model: {
    name: string;
    theme: ROOM_THEME;
    mode: ROOM_TYPE;
  }) => {
    this.socket.emit(
      "addRoom",
      {
        spaceId: this.spaceId,
        name: model.name,
        theme: model.theme,
        mode: model.mode,
      },
      () => {}
    );
  };

  createSlide = async (model: { name: string }): Promise<string> => {
    return new Promise((resolve) => {
      this.socket.emit(
        "addSlide",
        {
          spaceId: this.spaceId,
          name: model.name,
          identityId: this.identity,
        },
        (res: { id: string }) => {
          resolve(res.id);
        }
      );
    });
  };

  getRecordingsList = async (model: { roomId: string }) => {
    return new Promise((resolve) => {
      this.socket.emit(
        "listRecordingsByRoom",
        {
          identityId: this.identity,
          spaceId: this.spaceId,
          roomId: model.roomId,
        },
        (res: any) => {
          resolve(res);
        }
      );
    });
  };

  getDownloadLink = async (model: { link: string }) => {
    return new Promise((resolve) => {
      this.socket.emit(
        "getDownloadLink",
        {
          link: model.link,
        },
        (res: any) => {
          resolve(res);
        }
      );
    });
  };

  getCustomerPortalLink = async () => {
    return new Promise((resolve) => {
      this.socket.emit(
        "getCustomerPortalLink",
        {
          spaceId: this.spaceId,
        },
        (res: any) => {
          resolve(res);
        }
      );
    });
  };

  updateParticipant = async (model: {
    firstName: string;
    lastName: string;
    identityId: string;
    avatar: string;
  }) => {
    this.socket.emit(
      "updateParticipant",
      {
        spaceId: this.spaceId,
        firstName: model.firstName,
        lastName: model.lastName,
        avatar: model.avatar,
        identityId: model.identityId,
      },
      () => {}
    );
  };

  updateUserRole = async (model: { role: ROLE; identityId: string }) => {
    this.socket.emit(
      "updateRole",
      {
        spaceId: this.spaceId,
        role: model.role,
        identityId: model.identityId,
      },
      () => {}
    );
  };

  duplicateSlide = async (model: { plotId: string }) => {
    this.socket.emit(
      "duplicateSlide",
      {
        spaceId: this.spaceId,
        plotId: model.plotId,
        identityId: this.identity,
      },
      (res: any) => {
        this.slides.push(res.slide);
      }
    );
  };

  applyLifetimeCode = async (model: { code: string; cb: (r: any) => void }) => {
    this.socket.emit(
      "applyLifetimeCode",
      {
        spaceId: this.spaceId,
        code: model.code,
      },
      (res: any) => {
        model.cb(res);
      }
    );
  };

  @action
  collapseSideBar = () => {
    this.spaceSidebarCollapsed = !this.spaceSidebarCollapsed;
  };
  @action
  openInviteModal = () => {
    this.isInviteModalOpen = true;
  };

  @action
  closeInviteModal = () => {
    this.isInviteModalOpen = false;
  };

  @action
  stopSoundNotification = () => {
    this.soundNotify = false;
  };

  @action
  setRecordingStarted = (flag: boolean) => {
    this.recordingStarted = flag;
  };

  @computed
  get isAdmin() {
    return this.role === ROLE.ADMIN;
  }

  @computed
  get isMemberOrAdmin() {
    return this.role === ROLE.ADMIN || this.role === ROLE.COLLABORATOR;
  }

  @computed
  get canJoinOtherRoom() {
    const rooms = this.rooms;
    const receptionRoom = rooms.find((itm) => itm.receptionRoom);
    if (receptionRoom) {
      if (this.currentRoom?.roomId === receptionRoom.roomId) {
        const participants = this.participants;
        const roomParticipants = participants.filter(
          (p) => p.roomId !== receptionRoom.roomId
        ).length;
        return roomParticipants <= this.seats;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getReceptionRoomId() {
    const receptionRoom = this.rooms.find((itm) => itm.receptionRoom);
    return receptionRoom?.roomId!;
  }
}
