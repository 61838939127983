import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import SpaceContainer from "../../features/space/SpaceContainer";
import MainRouter from "./main-router";
// import Account from "../private/account/AccountContainer";

const LoggedIn = () => {
  return (
    <Switch>
      <Route path="/space">
        <SpaceContainer />
      </Route>
      <Route exact path="/">
        <MainRouter />
      </Route>
      <Redirect to="/space" />
    </Switch>
  );
};

export default LoggedIn;
