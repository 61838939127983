import {
  createStyles,
  Card,
  Text,
  SimpleGrid,
  UnstyledButton,
  Group,
  rem,
} from "@mantine/core";
import {
  IconBulb,
  IconQuestionCircle,
  IconDeviceTvOld,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    maxWidth: "660px",
    margin: "0 auto",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : "white",
  },

  title: {
    fontFamily: `Outfit, ${theme.fontFamily}`,
    fontWeight: 600,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: rem(90),
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));

const Cards = () => {
  const mockdata = [
    {
      title: "Leave Feedback",
      icon: IconBulb,
      color: "blue",
      link: "https://feedback.cake.work/",
    },
    {
      title: "Got a question?",
      icon: IconQuestionCircle,
      color: "green",
      link: "https://309sov53iwj.typeform.com/to/Ti5MBxu8",
    },
    {
      title: "Onboarding Video",
      icon: IconDeviceTvOld,
      color: "orange",
      link: "https://www.youtube.com/watch?v=krx04M-r9mQ",
    },
  ];

  const { classes, theme } = useStyles();

  const items = mockdata.map((item) => (
    <a
      href={item.link}
      target={"_blank"}
      rel="noreferrer"
      className={classes.link}
    >
      <UnstyledButton key={item.title} className={classes.item}>
        <item.icon color={theme.colors[item.color][6]} size="2rem" />
        <Text size="xs" mt={7}>
          {item.title}
        </Text>
      </UnstyledButton>
    </a>
  ));

  return (
    <Card withBorder radius="sm" className={classes.card}>
      <Group position="apart">
        <Text size="lg" className={classes.title}>
          Quick start
        </Text>
        {/* <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
                    And many many more.
                </Anchor> */}
      </Group>
      <SimpleGrid cols={3} mt="md">
        {items}
      </SimpleGrid>
    </Card>
  );
};

export default Cards;
