import React, { useCallback, useState } from "react";
// @ts-ignore
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
// @ts-ignore
import SBConversation from "@sendbird/uikit-react/Channel";
// @ts-ignore
import SBChannelList from "@sendbird/uikit-react/ChannelList";
// @ts-ignore
import SBChannelSettings from "@sendbird/uikit-react/ChannelSettings";
// @ts-ignore
import withSendbird from "@sendbird/uikit-react/withSendbird";

function CustomizedApp(props: any) {
  // default props
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub,
    },
  } = props;
  const logDefaultProps = useCallback(() => {
    console.log(
      "SDK store list log",
      sdkStore.initialized,
      sdkStore.sdk,
      sdkStore.loading,
      sdkStore.error
    );
    console.log(
      "User store list log",
      userStore.initialized,
      userStore.user,
      userStore.loading
    );
    console.log(
      "Config list log",
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub
    );
  }, [
    sdkStore.initialized,
    sdkStore.sdk,
    sdkStore.loading,
    sdkStore.error,
    userStore.initialized,
    userStore.user,
    userStore.loading,
    isOnline,
    userId,
    appId,
    accessToken,
    theme,
    userListQuery,
    logger,
    pubSub,
  ]);
  logDefaultProps();

  // useState
  const [showSettings, setShowSettings] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");

  return (
    <div className="customized-app">
      <div className="sendbird-app__wrap">
        <div className="sendbird-app__channellist-wrap">
          <SBChannelList
            onChannelSelect={(channel: any) => {
              if (channel && channel.url) {
                setCurrentChannelUrl(channel.url);
              }
            }}
          />
        </div>
        <div className="sendbird-app__conversation-wrap">
          <SBConversation
            channelUrl={currentChannelUrl}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
          />
        </div>
      </div>
      {showSettings && (
        <div className="sendbird-app__settingspanel-wrap">
          <SBChannelSettings
            channelUrl={currentChannelUrl}
            onCloseClick={() => {
              setShowSettings(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

const Chat = withSendbird(CustomizedApp);

export default function App() {
  const APP_ID = "97D9076B-7A87-4FDF-A262-B8E0A7CBB64C";
  // set your own USER_ID and NICKNAME
  const USER_ID = "sendbirdian-200720";
  const NICKNAME = "Sendbirdian84";
  return (
    <div style={{ width: "1000px", height: "800px" }}>
      <SBProvider
        appId={APP_ID}
        userId={USER_ID}
        nickname={NICKNAME}
        profileUrl="https://ucarecdn.com/6028eb8b-4051-4b2d-a3d9-1da717b5e7f2/"
      >
        <Chat />
      </SBProvider>
    </div>
  );
}
