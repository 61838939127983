import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useStore } from "../../hooks/use-store";

import { createStyles, Modal } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    background: "black",

    "> div": {
      background: "black",
    },

    ".mantine-Modal-header": {
      background: "black",
      color: "white",
    },
    ".mantine-Modal-body": {
      background: "black",
    },

    section: {
      background: "black",
    },
  },
  body: {
    background: "black",
  },
}));
const UpgradeModal = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <script src="https://js.stripe.com/v3/"></script>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <Modal
        withCloseButton={false}
        opened={true}
        onClose={() => {}}
        title="Get started with plan"
        fullScreen
        className={classes.root}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <div className={classes.body}>
          {spaceStore.spaceId ? (
            <stripe-pricing-table
              client-reference-id={spaceStore.spaceId}
              pricing-table-id="prctbl_1MnKe3HiChCaLMT91GPQlyWD"
              publishable-key="pk_live_51KUSC5HiChCaLMT9abtEXpklngMI2PATybffaK8Pah0xw1Pj6Uloph69o8bYNFjdP8EirybEGh5z2VWHmUuP0z7E00NGMP9PWi"
            ></stripe-pricing-table>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default observer(UpgradeModal);
