import { Controller } from "react-hook-form";
import { TextInput, createStyles, PasswordInput } from "@mantine/core";

import styles from "./index.module.scss";
const getError = (type: string) => {
  if (type === "required") {
    return "This field is required";
  } else {
    return "Check this field";
  }
};

const useStyles = createStyles((theme) => ({
  invalid: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors.red[8], 0.15)
        : theme.colors.red[0],
  },

  icon: {
    color: theme.colors.red[theme.colorScheme === "dark" ? 7 : 6],
  },
}));

const FormInput = (props: any) => {
  const { classes } = useStyles();
  return (
    <div className={styles.container}>
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field }) =>
          props.type === "password" ? (
            <PasswordInput
              required={props.required}
              type={props.type}
              disabled={props.disabled}
              onChange={field.onChange}
              value={field.value}
              placeholder={props.placeholder}
              label={props.label}
              error={props.error ? getError(props.error?.type) : undefined}
              classNames={props.error ? { input: classes.invalid } : undefined}
            />
          ) : (
            <TextInput
              required={props.required}
              type={props.type}
              disabled={props.disabled}
              onChange={field.onChange}
              value={field.value}
              placeholder={props.placeholder}
              label={props.label}
              error={props.error ? getError(props.error?.type) : undefined}
              classNames={props.error ? { input: classes.invalid } : undefined}
            />
          )
        }
      />
    </div>
  );
};

export default FormInput;
