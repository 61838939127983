import { createStyles, Accordion, Container, Title, Card } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background: theme.colorScheme === "dark" ? "#25262b" : "white",
  },

  title: {
    marginBottom: theme.spacing.sm,
    paddingLeft: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
}));

const Faq = () => {
  const { classes } = useStyles();
  return (
    <Card withBorder radius="md">
      <div className={classes.wrapper}>
        <Container size="sm">
          <Title order={4} ta="left" className={classes.title}>
            Frequently Asked Questions
          </Title>

          <Accordion
            chevronPosition="right"
            defaultValue="reset-password"
            variant="separated"
          >
            <Accordion.Item className={classes.item} value="1">
              <Accordion.Control>
                Ok, i'm here. How to start session?
              </Accordion.Control>
              <Accordion.Panel>
                You can manage all your sessions on the left side. Click + to
                add a new session. Remember that a webinar is more suitable for
                public events, where you need to control who goes on stage. In
                classroom mode, all participants join the webinar with the
                camera and microphone turned on.
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="2">
              <Accordion.Control>
                I want to schedule a session. How do I set up a landing page?
              </Accordion.Control>
              <Accordion.Panel>
                Click the edit settings button in the room and configure the
                session name, agenda, and set entrance time.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item className={classes.item} value="3">
              <Accordion.Control>
                How do I share a link with other participants?
              </Accordion.Control>
              <Accordion.Panel>
                Click on "Copy Participant Link" below and share the link with
                participants. Participants will not see your other sessions.
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="4">
              <Accordion.Control>
                I've started recording. Where i can find it?
              </Accordion.Control>
              <Accordion.Panel>
                All of your recordings are stored in the cloud. You can see a
                list of recordings if you click "Recordings" in the drop-down
                list. Note that you can copy the public link if you want to
                share an recording with other participants.
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item className={classes.item} value="5">
              <Accordion.Control>
                I want embed Miro and Whiteboard. How can i do this?
              </Accordion.Control>
              <Accordion.Panel>
                After starting the session, click on the plugins button and
                select miro or whiteboard integration. Excalidraw, DocSync, and
                YouTube integrations are also available to you
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
    </Card>
  );
};

export default Faq;
