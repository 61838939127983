import React from "react";
import styles from "./loader.module.scss";
import { useStore } from "../../../hooks/use-store";
import Logo from "../../../components/Logo";
import { observer } from "mobx-react";

const FullscreenLoader = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();
  return (
    <div className={spaceStore.ready ? styles.hidden : styles.visible}>
      <div className={styles.block}>
        <span>Hold on a second</span>
        <span>We are baking your</span>
        <Logo />
      </div>
    </div>
  );
};

export default observer(FullscreenLoader);
