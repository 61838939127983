import React from "react";
import styles from "./logo.module.scss";

const Logo = () => (
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 93.12 27.63"
  >
    <defs></defs>
    <g>
      <path
        className={styles.cls5}
        d="M24.87,14.19c0-4.97,3.11-8.02,7.8-8.02,4.34,0,7.17,2.52,7.17,6.64h-3.18c-.03-2.33-1.54-3.74-3.9-3.74-2.7,0-4.37,1.92-4.37,5.13s1.64,5.13,4.25,5.13,4.03-1.38,4.06-3.68h3.18c0,4.15-2.96,6.57-7.39,6.57s-7.61-3.05-7.61-8.02Z"
      />
      <path
        className={styles.cls5}
        d="M41.79,17.49c0-2.36,1.67-4.21,5.82-4.21h5.76v-1.1c0-2.33-1.35-3.37-4.09-3.37-2.39,0-3.74,.91-3.74,2.64,0,.09,0,.35,.03,.6h-3.24c-.03-.22-.06-.63-.06-.91,0-3.11,2.7-4.97,7.14-4.97,4.72,0,7.48,2.23,7.48,6.32v9.47h-3.52c.06-.75,.16-2.17,.16-3.43h-.03c-.5,2.3-2.36,3.68-5.79,3.68s-5.91-1.7-5.91-4.72Zm11.57-1.79v-.28h-5.44c-2.01,0-2.89,.69-2.89,1.92,0,1.42,1.26,2.23,3.4,2.23,3.05,0,4.94-1.48,4.94-3.87Z"
      />
      <path
        className={styles.cls5}
        d="M60.35,.16h3.52V14.57h.03l8.37-8.15h4.56l-7.26,6.76,7.67,8.77h-4.78l-5.47-6.54-3.11,2.86v3.68h-3.52V.16Z"
      />
      <path
        className={styles.cls5}
        d="M93.12,14.88h-11.64c.19,2.99,1.86,4.59,4.5,4.59,2.26,0,3.81-1.01,3.96-2.83h3.18c-.12,3.46-2.96,5.57-7.2,5.57-4.91,0-7.99-3.02-7.99-7.89s3.08-8.15,7.99-8.15c4.4,0,7.2,2.61,7.2,7.45v1.26Zm-3.18-2.36c0-2.33-1.45-3.62-3.99-3.62s-3.96,1.26-4.37,3.84h8.37v-.22Z"
      />
    </g>
    <path
      className={styles.cls7}
      d="M13.83,.01V13.8L23.59,4.04S20.29,.01,13.83,.01Z"
    />
    <path
      className={styles.cls1}
      d="M4.08,4.06L13.83,13.81V.02S8.65-.5,4.08,4.06Z"
    />
    <path
      className={styles.cls4}
      d="M.01,13.8H13.8L4.04,4.04S.01,7.34,.01,13.8Z"
    />
    <path
      className={styles.cls6}
      d="M4.06,23.58L13.81,13.83H.02s-.52,5.18,4.05,9.75Z"
    />
    <path
      className={styles.cls2}
      d="M13.84,27.62V13.83S4.08,23.59,4.08,23.59c0,0,3.3,4.03,9.75,4.03Z"
    />
    <path
      className={styles.cls3}
      d="M23.58,23.57L13.84,13.82v13.79s5.18,.52,9.75-4.05Z"
    />
  </svg>
);

export default Logo;
