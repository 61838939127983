import {
  createStyles,
  Button,
  Menu,
  Group,
  ActionIcon,
  rem,
} from "@mantine/core";
import { IconTrash, IconCalendar, IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";
import EditRoomDrawer from "../../components/sidebar/editRoom/EditRoom";
import { useStore } from "../../hooks/use-store";
import RoomRecordings from "../../components/sidebar/roomRecordings/RoomRecordings";

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));

const RoomControls = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();
  const { classes, theme } = useStyles();
  const menuIconColor =
    theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 5 : 6];

  const [roomRecordingsDrawerOpen, setRoomRecordingsDrawerOpen] =
    useState(false);
  const [editRoomDrawerData, setEditRoomDrawerData] = useState(false);
  return (
    <Group noWrap spacing={0} ml={10}>
      <Button
        size={"xs"}
        className={classes.button}
        onClick={() => setEditRoomDrawerData(true)}
      >
        Settings
      </Button>
      <Menu
        transitionProps={{ transition: "pop" }}
        position="bottom-end"
        withinPortal
      >
        <Menu.Target>
          <ActionIcon
            // size={'xs'}
            variant="filled"
            color={theme.primaryColor}
            size={30}
            className={classes.menuControl}
          >
            <IconChevronDown size="1rem" stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={() => setRoomRecordingsDrawerOpen(true)}
            icon={
              <IconCalendar size="1rem" stroke={1.5} color={menuIconColor} />
            }
          >
            Recordings
          </Menu.Item>
          <Menu.Item
            onClick={async () => {
              await spaceStore.deleteRoom({
                roomId: spaceStore.currentRoom!.roomId,
              });
              await spaceStore.changeRoom(spaceStore.getReceptionRoomId());
            }}
            icon={<IconTrash size="1rem" stroke={1.5} color={menuIconColor} />}
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <EditRoomDrawer
        visible={editRoomDrawerData}
        onClose={() => setEditRoomDrawerData(false)}
        room={spaceStore.currentRoom!}
      />

      {roomRecordingsDrawerOpen ? (
        <RoomRecordings
          visible={!!roomRecordingsDrawerOpen}
          roomId={spaceStore.currentRoom!.roomId}
          onClose={() => setRoomRecordingsDrawerOpen(false)}
        />
      ) : null}
    </Group>
  );
};

export default RoomControls;
